<template>
    <base-layout :page-title="loadedSwap ? loadedSwap.title : 'Loading...'">
        <div class="h-full">
            <h2 v-if="!loadedSwap">Could not find a swap for the given id.</h2>
            <h2 v-if="room !== ''" id="roomName" class="flex relative z-50 justify-center items-center pt-2 text-center text-gray-400 bg-white bg-opacity-40 backdrop-filter backdrop-blur-xl transition duration-150" ref="roomName">{{ room }}</h2>
            <div class="overflow-hidden relative mx-auto h-full" id="stack">
                <ion-card
                    ref="vueswing"
                    v-for="swap in loadedSwap"
                    :key="swap"
                    :id="swap.id"
                    class="flex overflow-hidden absolute top-0 right-4 left-4 bottom-5 flex-col flex-wrap content-end mx-auto max-w-sm rounded-xl border transition-all duration-700 transform cursor-pointer scale-80 card"
                >
                    <div class="absolute">
                    <ion-img :src="swap.second_image" class="object-cover col-span-2 w-full h-auto rounded-t-xl hsm:hidden" alt="main image"></ion-img>
                    <ion-img :src="swap.image" class="hidden object-cover col-span-2 w-full h-auto rounded-t-xl hsm:block" alt="main image" ></ion-img>
                    </div>
                    <div class="flex relative flex-col flex-wrap mt-auto w-full">
                        <div class="px-2 mt-auto w-full bg-gradient-to-b from-transparent via-white to-white gradient sm:pt-4 sm:px-4 hsm:px-4 hsm:pt-4 text-gray-900">
                            <h2 class="pt-10 w-full text-2xl font-bold hmd:pt-40">{{ swap.title }}</h2>
                            <div class="w-full">
                                <ul class="flex flex-wrap gap-2 mt-3 mb-3 text-xs sm:text-sm">
                                    <li
                                        class="px-2 py-1 text-white bg-gray-500 rounded-full opacity-95"
                                        v-for="con in swap.cons"
                                        :key="con"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" class="inline w-4 h-4">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h42v42H0z"/>
                                                <g fill="currentColor" transform="translate(3 3)">
                                                    <circle cx="11.769" cy="15.923" r="2.077"/>
                                                    <path d="M18 20.77c3.93 0 7.236 2.555 8.283 6.042a.692.692 0 0 1-.681.88H10.398a.692.692 0 0 1-.677-.88c1.037-3.487 4.344-6.043 8.279-6.043Z"/>
                                                    <circle cx="24.231" cy="15.923" r="2.077"/>
                                                    <path d="M18-1.5C7.23-1.5-1.5 7.23-1.5 18S7.23 37.5 18 37.5 37.5 28.77 37.5 18 28.77-1.5 18-1.5Zm0 3c9.113 0 16.5 7.387 16.5 16.5S27.113 34.5 18 34.5 1.5 27.113 1.5 18 8.887 1.5 18 1.5Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                        {{ con.name }}
                                    </li>
                                </ul>
                                <div class="hidden w-full hsm:block">
                                    <div class="line-clamp-3" v-html="swap.content"></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex p-2 w-full pointer-events-auto button-row sm:p-4 hsm:p-4 invisible">
                            <div class="flex justify-between items-center self-end w-full">
                                <ion-button fill="clear" class="w-16 h-16 text-white rounded-full" @click="onClickOutLeft">
                                    <ion-img :src="require('@/assets/Icons/swap-reject-circle-col.svg')" class="w-16 h-16"></ion-img>
                                </ion-button>
                                <ion-button icon-only fill="clear" class="w-8.5 h-8.5 text-xl text-gray-300" @click="openSwapModal(swap)">
                                    <ion-icon slot="icon-only" :icon="informationCircle"></ion-icon>
                                </ion-button>
                                <ion-button icon-only fill="clear" class="w-16 h-16 text-white rounded-full" @click="onClickOutRight">
                                    <ion-img :src="require('@/assets/Icons/swap-progress.svg')" class="w-16 h-16"></ion-img>
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </ion-card>
                <ion-card
                    v-if="noMoreCards()"
                    class="border-2 border-gray-200 bg-gray-100
                            flex absolute top-0 bottom-5 flex-col flex-wrap content-end transition duration-700 transform cursor-pointer sm:max-w-sm"
                >
                    <div class="flex flex-col justify-center p-4 h-full">
                        <ion-img :src="require('@/assets/Icons/swap-happy.svg')" class="block mx-auto mt-10 mb-4 w-10 h-10"></ion-img>
                        <div class="text-center">
                            <h2 class="text-lg font-bold text-green-500">You've Completed This Room</h2>
                            <p class="pb-4 text-md text-smcc-grey-dark">Nice work, you are on your sustainable journey supporting the einvironment, thank you.</p>
                            <p class="text-md text-smcc-grey-dark">We'll notify you when we add more sustainable swaps.</p>
                        </div>
                        <ion-button fill="clear" class="block mt-4 w-full font-bold text-green-500 bg-white rounded border-2 border-green-500 h-15" @click="$router.go(-1)">
                            <ion-icon :src="require('@/assets/Icons/arrow-back.svg')" class="px-2 py-6 w-6 h-6"></ion-icon> Choose Your Next Room
                        </ion-button>
                    </div>
                </ion-card>
            </div>
        </div>
    </base-layout>
</template>

<script>
console.info('Swaps Page');
import {IonButton, IonIcon, IonImg, modalController, IonCard} from '@ionic/vue';
import { get, set } from '@/store/storage';
import BaseLayout from "@/components/base/BaseLayout";
import Hammer from "hammerjs";
import {useRouter} from 'vue-router';
import {imagesOutline, informationCircle, closeOutline, closeCircleOutline, gridOutline, arrowForward} from 'ionicons/icons';
import SwapModal from '@/components/SwapModal.vue';
import { inject, defineComponent } from 'vue';

export default defineComponent({
    grabbedCard: null,
    components: {
        BaseLayout,
        IonButton,
        IonIcon,
        IonImg,
        IonCard
    },
    setup() {
        const openSwapModal = async (id) => {
            const modal = await modalController.create({
                component: SwapModal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id
                },
                swipeToClose: true,
                backdropDismiss: true
            });
            return modal.present();
        };

        return {
            openSwapModal,
            closeCircleOutline,
            imagesOutline,
            informationCircle,
            closeOutline,
            gridOutline,
            arrowForward,
            router: useRouter(),

        };
    },
    data() {

        const emitter = inject("emitter");   // Inject `emitter`
        return {
            room: '',
            declined_products: [],
            accepted_products: [],
            declined_swaps: [],
            roomId: this.$route.params.id,
            loadedSwap: [],
            emitter
        }
    },
    computed: {
        currentCard() {
            return this.loadedSwap[this.loadedSwap.length - 1];
        },
    },
    methods: {
        async grabTitle() {
            const roomLoaded = await this.$store.getters.room(parseInt(this.$route.params.id));
            return (roomLoaded) ? roomLoaded.title : '';
        },
        noMoreCards() {
          if(this.loadedSwap.length === 0) {
              return true;
          }
        },
        stackCount() {
            const stack = document.getElementById('stack');
            return stack.childElementCount
        },
        remove() {
            setTimeout(() => {
                this.$refs.vueswing.pop()
            }, 100)
        },
        /**
         * Restack the card reverting any CSS to default
         * @param target
         */
        restack(target) {
            setTimeout(() => {
                const stack = document.getElementById("stack");

                /**
                 * If we dont keep track of what cards are left the card gets positioned
                 * behind the stack in the wrong place
                 */
                if(this.stackCount() > 1) {
                    stack.insertBefore(target, stack.firstChild.nextSibling);
                }
                target.style['transform'] = `translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`;
            }, 50)
        },
        /**
         * Reset card to center of screen
         * @param target
         */
        reset(target) {
            setTimeout(() => {
                target.style['transform'] = `translate3d(0, 0, 0) translate(0px, 0px) rotate(0deg)`;
            }, 1000);
        },
        /**
         * Click handler for decline action
         * @param ev
         */
        onClickOutLeft(ev) {
            const r = Math.floor(Math.random() * (25 - 10 + 1) + 10)
            this.grabbedCard = ev.target.closest(".card");
            this.grabbedCard.style['transform'] = `translate3d(-500px, 0, 0) translate(-500px, 0px) rotate(${r}deg)`;
            this.grabbedCard.style['transition'] = `all 0.7s 0.1s ease-out`;
            setTimeout(()=> {
                this.onThrowOutLeft(this.grabbedCard);
            },200);
        },
        /**
         * Decline a product to swap action
         * Should return to rooms list / show next room when array empty
         * @param target
         */
        onThrowOutLeft(target) {
            const room = this.roomId;
            const swap = target.id;
            this.declined_swaps.push({room, swap});
            set('declined_swaps', this.declined_swaps)
            set('last_action', {set:'declined_swaps', value: {room, swap} });
            this.emitter.emit("action", true);
            this.componentDidLoad()
            target.remove()
        },
        async componentDidLoad() {
            this.loadedSwap = await this.$store.getters.filterSwaps(this.$route.params.id);
        },
        /**
         * Click handler for accept action
         * @param ev
         */
        onClickOutRight(ev) {
            this.grabbedCard = ev.target.closest(".card");
            this.onThrowOutRight(this.grabbedCard);
        },
        /**
         * Accept a product to swap action
         * @param target
         */
        onThrowOutRight(target) {
            if (target !== null) {
                this.router.push({path: '/home/rooms/' + this.$route.params.id + '/swaps/' + target.id + '/products', append: true})
                this.reset(target);
            }
        },
        /**
         * Restack the cards on swipe up or down
         * @param target
         */
        onThrowOutDown(target) {
            this.restack(target)
        },
        onThrowOutUp(target) {
            this.restack(target)
        },
        /**
         * If the card hasnt been thrown far enough reset into original position
         * @param target
         */
        onThrowOutEnd(target) {
            this.reset(target);
        },
        changeTitle(revert = false, decision = '') {

            const title = this.$refs.roomName;
            const originalTitle = this.room;
            if(decision === 'reject') {
                title.innerHTML = "Reject"
                title.classList.add('text-red-500');
                title.classList.remove('text-green-500');
            } else if(decision === 'liked') {
                title.innerHTML = "Swap"
                title.classList.add('text-green-500');
                title.classList.remove('text-red-500');
            }

            if(revert === true) {
                title.innerHTML = originalTitle;
                title.classList.remove('text-red-500');
                title.classList.remove('text-green-500');
            }
        }

    },
    async mounted() {
        this.room = await this.grabTitle();
        this.emitter.on("undo", async (value) => {   // *Listen* for event
            console.log("undo", `value: ${value}`);
            const action = await get('last_action');
            const object = JSON.stringify(action.value);
            const filter = this[action.set].filter(function(a){ return JSON.stringify(a) !== object });
            const setter = action.set;
            console.log(setter)
            await set(setter, filter);
            this.loadedSwap = await this.$store.getters.filterSwaps(this.$route.params.id);
        });

        this.hammer = new Hammer.Manager(this.$el, {
            inputClass: Hammer.TouchMouseInput,
            recognizers: [
                //[Hammer.Swipe, { direction: Hammer.DIRECTION_ALL }],
                [Hammer.Tap],
                [Hammer.Pan],
                //[Hammer.Press, { time: 1, threshold: 1000000 }],
            ],
        });
        this.hammer.on("pan", (ev) => {
            const card = ev.target.closest(".card");
            if (card === null) {
                return;
            }
            this.grabbedCard = card;
            const x = ev.deltaX;
            const y = ev.deltaY;
            const r = this.rotation(x, y, ev.target, 25);
            card.style.transitionDuration = '0ms'
            card.style['transform'] = `translate3d(0, 0, 0) translate(${x}px, ${y}px) rotate(${r}deg)`;
            if (ev.deltaX >= 200) {
                this.changeTitle(false, 'liked');
                card.style['border-width'] = '2px';
                card.style['border-color'] = 'rgba(118, 189, 29, var(--tw-border-opacity))';
            }
            if (ev.deltaX <= -200) {
                this.changeTitle(false, 'reject');
                card.style['border-width'] = '2px';
                card.style['border-color'] = 'rgba(198, 58, 57, var(--tw-border-opacity))';
            }
        });
        this.hammer.on("panend", (ev) => {
            const card = this.grabbedCard;
            if (ev.deltaY >= 50 || ev.deltaY <= -50) {
                this.restack(card)
            }
            if (ev.deltaX >= 250) {
                this.onThrowOutRight(card);
            }
            if (ev.deltaX <= -250) {
                this.onThrowOutLeft(card);
            }
            card.style['transform'] = `translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`;
            card.style.transitionDuration = '700ms'
            card.style['border-width'] = '0px';
            this.changeTitle(true);
        });
        this.rotation = function (coordinateX, coordinateY, element, maxRotation) {
            const horizontalOffset = Math.min(Math.max(coordinateX / element.offsetWidth, -1), 1);
            const verticalOffset = (coordinateY > 0 ? 1 : -1) * Math.min(Math.abs(coordinateY) / 100, 1);
            return horizontalOffset * verticalOffset * maxRotation;
        };
    },
    async beforeCreate() {
        this.loadedSwap = await this.$store.getters.filterSwaps(this.$route.params.id);
        this.declined_swaps = await get('declined_swaps') || [];
        this.declined_products = await get('declined_products') || [];
        this.accepted_products = await get('accepted_products') || [];
    },
    async ionViewWillEnter() {
        this.loadedSwap = await this.$store.getters.filterSwaps(this.$route.params.id);
        this.declined_swaps = await get('declined_swaps') || [];
        this.declined_products = await get('declined_products') || [];
        this.accepted_products = await get('accepted_products') || [];
        console.info(this.loadedSwap)
    },

});
</script>
<style scoped>
.card:last-child {
    @apply bg-gray-900 shadow-lg scale-100 text-white;
}
.card:last-child h2 {
    @apply text-white
}
.card:last-child .button-row {
    @apply bg-gray-900 visible
}
.card:last-child .gradient {
    @apply from-transparent via-gray-900 to-gray-900
}
.card:nth-last-child(2) {
    @apply shadow-md scale-90 translate-y-8 hsm:translate-y-8 hmd:translate-y-10 hlg:translate-y-10 hxl:translate-y-16  !important;
}
.card:nth-last-child(3) {
    @apply shadow translate-y-16 hsm:translate-y-16 hmd:translate-y-20 hlg:translate-y-20 hxl:translate-y-32 !important;
}
ion-button {
    padding: 0;
    --padding-start: 0;
    --padding-end: 0;
}

ion-icon {
    --ionicon-stroke-width: 16px;
}
.ios #stack {
    @apply -mt-4
}
.md .card {
    @apply bottom-9
}
</style>
